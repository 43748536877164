.sn_form {
	.form-group {
		position: relative;
		margin-bottom: 25px;

		@include mq($db){
			margin-bottom: 35px;
		}

		&._float {
			label {
				font-weight: fw(medium);
				color: $primary;
				transform: scale3d(.6364, .6364, .6364) translate3d(0, -80%, 0);

				@include mq(md){
					transform: scale3d(.6364, .6364, .6364) translate3d(0, -60%, 0);
				}
			}
		}

		&._file {
			.form-control {
				padding-top: 0;
				display: flex;
				align-items: center;

				.name {
					flex-grow: 1;
				}

				.link {
					@include font-size(14px);
					margin-left: 10px;
					font-weight: fw(medium);
				}
			}

			.btn {
				position: relative;

				input[type="file"] {
					@include scut-absolute(0 0 0 0);
					@include size(100%);
					opacity: 0;
					cursor: pointer;
				}
			}
		}

		.form-control {
			border-width: 0 0 2px 0;
			border-color: $primary;
			padding: 15px 20px 0;
			border-radius: 5px 5px 0 0;
			background-color: rgba($light, .3);

			&:not(textarea) {
				height: 60px;
			}

			@include mq(md){
				padding-top: 20px;
			}

			&.is-invalid {
				border-color: $danger;
				background-position: right 20px center;

				& + label {
					color: $danger;
				}
			}
		}

		textarea.form-control {
			padding-top: 25px;
			padding-bottom: 25px;

			&.is-invalid {
				background-position: right 15px top 18px;
			}
		}

		label {
			@include scut-absolute(((60px - 18px) / 2) 20px n 20px);
			@include font-size(16px, 20px);
			display: block;
			margin: 0;
			pointer-events: none;
			transition: $transition-base;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
			line-height: 18px;
			transform: translate3d(1, 1, 1) translate3d(0, 0, 0);
			transform-origin: left top;

			@include mq(md){
				top: ((60px - 24px) / 2);
				line-height: 24px;
			}
		}
	}

	.custom-select {
		border-width: 0 0 2px 0;
		border-color: $primary;
		padding: 20px 60px 0 20px;
		border-radius: 5px 5px 0 0;
		background-color: rgba($light, .3);
		height: 60px;

		& + label {
			right: 60px;
		}

		&.is-invalid {
			border-color: $danger;
			background-position: right 45px center, right 15px center;
			padding-right: 80px;

			& + label {
				color: $danger;
			}
		}
	}

	.custom-control {
		margin-bottom: 25px;

		@include mq($db){
			margin-bottom: 35px;
		}
	}

	.invalid-feedback {
		@include font-size(14px);
		font-weight: fw(bold);
		margin-top: 5px;
	}
}

// Common
form {
	.form-group {
		.form-control {
			box-shadow: none !important;
		}
	}

	.custom-select {
		box-shadow: none !important;
	}

	.custom-control {
		&-label {
			@include font-size(12px, 14px);
			padding-top: 7px;
			display: inline-block;

			@include mq($db){
				padding-top: 6px;
			}

			&::before {
			}

			a {
				@extend .sn_link_bold;
				@extend .text-body;
			}
		}
	}
}
