.sn_accordion {

	&._small {
		&, p {
			@include font-size(16px, 18px);
		}
	}

	&_i {
		padding-bottom: 10px;

		&_title {
			@include scut-padding(14px 20px);
			@extend .h4;
			position: relative;
			display: flex;
			align-items: center;
			justify-content: space-between;
			border: 0;
			width: 100%;
			border-radius: 10px;
			background: $light;
			appearance: none;
			margin-bottom: -10px;
			text-align: left;
			z-index: 1;
			color: $body-color;
			font-weight: fw(bold);

			@include mq($db){
				@include scut-padding(16px 30px);
			}

			&:hover,
			&:active {
				background: darken($light, 5%);
			}

			&:focus {
				outline: 0 !important;
			}

			&.collapsed {
				> i {
					transform: rotate3d(0, 0, 1, -90deg);

					&::after {
						opacity: 1;
					}
				}
			}

			._dark & {
				background: $secondary;
				color: #fff;
			}

			> i {
				@include size(28px);
				display: block;
				margin-left: auto;
				position: relative;
				transition: $transition-base;
				margin-left: 10px;
				flex-shrink: 0;
				transform: rotate3d(0, 0, 1, 0deg);

				&::before,
				&::after {
					@include size(100%, 2px);
					@include scut-absolute(50% n n 0);
					content: '';
					background: currentColor;
					display: block;
					margin-top: -1px;
					transition: $transition-base;
				}

				&::after {
					transform: rotate(90deg);
					opacity: 0;
				}
			}
		}

		&_content {
			overflow: hidden;
			border-radius: 0 0 10px 10px;

			&_in {
				@include scut-padding(30px 20px 20px);
				background-color: rgba($light, 0.3);

				@include mq($db){
					@include scut-padding(40px 30px 30px);
				}

				@include headings {
					color: $primary;
					border-bottom: 2px solid currentColor;
					padding-bottom: 5px;
					margin-bottom: 20px;
				};
			}

			&_num {
				@include mq($db){
					border-bottom: 1px solid #fff;
				}

				.value {
					width: 100%;
					border-bottom: 1px solid #fff;
					line-height: .8;

					@include mq($db){
						border-bottom: 0;
					}
				}

				.name {
					@include mq($db){
						text-align: right;
						display: inline;
					}
				}
			}
		}
	}
}
