.sn_footer {
	@include paragraph(16px, 18px);

	&_logo {
		width: 140px;

		@include mq($db){
			width: 200px;
		}
	}

	&_info {
		margin-bottom: 20px;
		line-height: 1.8;

		@include mq($db){
			margin-bottom: 0;
		}

		&:last-child {
			margin-bottom: 0;
		}

		.sn_sprite {
			@include size(30px);
			fill: $secondary;
		}
	}

	&_menu {
		ul {
			padding: 0;
			margin: 0;
			list-style: none;

			li {
				// margin-bottom: 5px;

				// @include mq($db){
				// 	margin-bottom: 10px;
				// }

				// &:last-child {
				// 	margin-bottom: 0;
				// }

				a {
					text-decoration: none;
					line-height: 1.8;

					&.collapsed {
						.sn_sprite {
							transform: rotate3d(1, 0, 0, 0deg);
						}
					}

					&[data-toggle="collapse"] {
						@include mq(md){
							pointer-events: none;
						}
					}

					.sn_sprite {
						@include size(10px);
						fill: currentColor;
						transform: rotate3d(1, 0, 0, 180deg);
						transition: transform $transition-base-easing;
					}
				}

				ul {
					// padding-top: 5px;

					// @include mq($db){
					// 	padding-top: 10px;
					// }
				}

				.collapse {
					@include mq(md){
						height: auto !important;
						display: block !important;
					}
				}
			}
		}
	}

	&_copy {
		@include paragraph(12px, 14px);
	}

	&_socials {
		a {
			@extend .sn_link_bold;
		}
	}

	a {
		@extend .text-body;
		text-decoration: underline;
	}
}
