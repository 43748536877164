.sn_sections_list {
	overflow: hidden;
	position: relative;
	z-index: 1;

	&_title {
		@include font-size(20px);
		font-weight: fw(bold);
	}

	&_i {
		&_title {
			&._image {
				min-height: 80px; // Taken from devtools, circa

				@include mq(md){
					min-height: 130px; // Taken from devtools, circa
				}
			}
		}

		&_img {
			@include size(90vw, 130px);
			@include scut-absolute(n ($grid-gutter-width / 2) 0 n);
			background-size: contain;
			background-position: right bottom;
			background-repeat: no-repeat;
			z-index: -1;

			@include mq($db){
				@include size(45vw, 200px);
			}
		}

		&_line {
			height: 2px;
		}
	}

	//

	@include paragraph(16px, 20px) {
		line-height: 1.5;
	}
}
