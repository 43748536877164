.sn_tabs_highlights {
	display: flex;
	align-items: flex-start;
	min-height: calc(100vh - #{$header-height-m});

	@include mq($db){
		min-height: calc(100vh - #{$header-height-d});
		align-items: center;
	}

	// Fix ‘min-height’ flex IE
	&::after { content: ''; min-height: inherit; font-size: 0; }

	&_head {
		.swiper-container {
			.swiper-wrapper {
				flex-wrap: nowrap;

				.swiper-slide {
					@extend .text-body;
					width: auto;
					display: inline-block;
					padding: 15px 30px;
					transition:
						color $transition-base-easing,
						border-color $transition-base-easing;
					border-bottom: 2px solid rgba($primary, 0);

					&.active {
						color: $primary !important;
						font-weight: fw(bold);
						border-bottom-color: $primary;
					}
				}
			}
		}
	}

	&_content {

	}
}
