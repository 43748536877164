.sn_quote_logo {
	position: relative;
	overflow: hidden;
	display: flex;
	align-items: center;
	min-height: calc(100vh - #{$header-height-m});

	@include mq($db){
		min-height: calc(100vh - #{$header-height-d});
	}

	// Fix ‘min-height’ flex IE
	&::after { content: ''; min-height: inherit; font-size: 0; }

	&_ct {
		padding-top: 30px;
		padding-bottom: 30px;
		position: relative;
		z-index: 1;
	}

	&_bg {
		@include scut-ratio-box;
		@include scut-absolute(50% n n 50%);
		width: 65.41vh;
		transform: translate(-50%, -50%);
		fill: rgba($light, .9);

		.sn_sprite {
			@include scut-absolute(0 0 0 0);
			animation-timing-function: linear;
			animation-iteration-count: infinite;
			animation-direction: reverse;

			&._logo_shape_small {
				animation-name: snQuoteLogoShapes1;
				animation-duration: 25s;
			}

			&._logo_shape_big {
				animation-name: snQuoteLogoShapes2;
				animation-duration: 30s;
			}
		}
	}

	blockquote {
		&, * {
			@extend .h1;
			@extend .sn_fw_thin;
			line-height: .98;
		}
	}
}

@keyframes snQuoteLogoShapes1 {
	0%		{ transform: rotate3d(0, 0, 1, 0deg); }
	100%	{ transform: rotate3d(0, 0, 1, 360deg); }
}

@keyframes snQuoteLogoShapes2 {
	0%		{ transform: rotate3d(0, 0, 1, 360deg); }
	100%	{ transform: rotate3d(0, 0, 1, 0deg); }
}
