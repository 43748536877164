/*
	Yoast Breadcrumb HTML:
	<nav id="breadcrumbs" class="main-breadcrumbs">
		<span>
			<span>
				<a href="#" >Homepage</a>
				<span class="separator"></span>
				<span class="breadcrumb_last" aria-current="page">Lorem ipsum</span>
			</span>
		</span>
	</nav>
*/

.sn_breadcrumb {
	position: relative;
	z-index: 1;

	&._light {
		a,
		.breadcrumb_last,
		.separator {
			@include font-size(14px);
			color: #fff;
		}
	}

	&_in {
		@include scut-absolute($header-height-m 0 n 0);
		margin-top: 20px;

		@include mq($db){
			top: $header-height-d;
		}
	}

	nav {
		font-size: 0;
		font-weight: fw(medium);

		a,
		.breadcrumb_last,
		.separator {
			@include font-size(14px);
			color: $body-color;
			display: inline-block;
		}

		a {
			text-decoration: underline;

			&:hover {
				color: $primary;
			}
		}

		.separator {
			margin: 0 3px;

			&::before {
				content: '/';
			}
		}
	}
}

