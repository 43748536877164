.sn_slider_clients {
	position: relative;
	overflow: hidden;

	&_bg {
		@include scut-absolute(0 0 0 0);
		transition: $transition-base;

		&_solid {
			@include scut-absolute(0 0 n -50vw);
			width: 200vw;

			@include mq(md){
				left: 0;
			}

			._wide & {
				left: -100vw;
			}
		}

		* {
			height: 100%;
		}
	}

	&_links {
		@include font-size(16px);
	}

	&_i {
		@include scut-ratio-box(138/122);
		display: block;
		background: #fff;
		box-shadow: 0 0 6px rgba(#000, .5);

		@include mq($db){
			@include scut-ratio-box(312/225);
		}

		figure {
			@include scut-absolute(0 0 0 0);
			padding: 30px;
			display: flex;
			align-items: center;
			justify-content: center;

			img {
				display: block;
				max-width: 100%;
				max-height: 100%;
				object-fit: contain;
			}
		}
	}

	a#{&}_i {
		&:hover,
		&:active {
			box-shadow: 0 0 15px rgba(#000, .3);
		}
	}

	//

	.swiper-container {
		overflow: visible;

		.swiper-wrapper {
			.swiper-slide {

			}
		}
	}

	//

	@include paragraph {
		line-height: 1.5;
	};
}
