.sn_timeline {
	@extend .bg-secondary;
	@extend .text-white;
	position: relative;
	overflow: hidden;

	&_bg {
		@include scut-absolute(0 0 0 0);
		background-size: cover;
		background-position: center center;
		background-repeat: no-repeat;
	}

	&_video {
		@include scut-absolute(0 0 0 0);

		.plyr {
			@include scut-absolute(0 0 0 0);

			.plyr__video-wrapper {
				@include scut-absolute(0 0 0 0);
				padding: 0 !important;

				iframe {
					@include size(100vw, 100vh);
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					border: 0;
					border: 1px solid red;

					@media (min-aspect-ratio: 16/9) {
						/* height = 100 * (9 / 16) = 56.25 */
						height: 56.25vw;
					}

					@media (max-aspect-ratio: 16/9) {
						/* width = 100 / (9 / 16) = 177.777777 */
						width: 177.78vh;
					}
				}
			}
		}
	}

	&_ct {
		position: relative;
		z-index: 1;
	}

	&_row {
		height: calc(100vh - #{$header-height-m});

		@include mq($db){
			height: calc(100vh - #{$header-height-d});
		}
	}

	&_navigation {
		z-index: 10;
		align-self: flex-start;

		@include mq($db){
			align-self: center;
		}

		&_in {
			@include scut-absolute(0 n n 50%);
			display: flex;
			padding-top: 50px;
			transform: translateX(-50%);

			@include mq($db){
				display: block;
				padding-top: 0;
			}

			&:hover,
			&:active {
				span {
					opacity: 0 !important;
				}
			}
		}

		&_i {
			@include size(13px, 45px);
			position: relative;
			cursor: pointer;

			@include mq($db){
				@include size(45px, 13px);
				max-width: 100%;
			}

			&:hover,
			&:active,
			&._active {
				&::before {
					opacity: 0;
				}

				&::after {
					opacity: 1;

					@include mq(0px, $dbm){
						height: 100%;
					}

					@include mq($db){
						width: 100%;
					}
				}

				span {
					opacity: 1;
					transform: translate(-50%, -5px) translate3d(0, 0px, 0);

					@include mq($db){
						transform: translate(10px, -50%) translate3d(0px, 0, 0);
					}
				}
			}

			&:hover,
			&:active {
				span {
					opacity: 1 !important;
				}
			}

			&._active {
				// pointer-events: none;
			}

			&::before {
				@include scut-absolute(0 n n ((13px - 1px) / 2));
				@include size(1px, 12px);
				content: '';
				transition: $transition-base;
				background: #fff;
				opacity: 1;

				@include mq($db){
					@include scut-absolute(((13px - 1px) / 2) 0 n a);
					@include size(12px, 1px);
				}
			}

			&::after {
				@include scut-absolute(0 n n ((13px - 5px) / 2));
				@include size(5px, 12px);
				content: '';
				transition: $transition-base;
				background: #fff;
				opacity: 0;

				@include mq($db){
					@include scut-absolute(((13px - 5px) / 2) 0 n a);
					@include size(12px, 5px);
				}
			}

			span {
				@include scut-absolute(n n 100% 50%);
				@include font-size(20px, 14px, $db);
				display: block;
				opacity: 0;
				font-weight: fw(bold);
				transition: $transition-base;
				transform: translate(-50%, -5px) translate3d(0, -15px, 0);

				@include mq($db){
					@include scut-absolute(50% n a 100%);
					transform: translate(10px, -50%) translate3d(15px, 0, 0);
				}
			}
		}
	}

	&_years {
		height: 100%;

		@include mq(md){
			padding-top: 20vh;
			padding-bottom: 0;
		}

		@include mq(0px, sm-max){
			@include scut-absolute(120px 0 0 0);
			@include scut-padding(n $container-mobile-padding);
			transition: transform $transition-slow-easing;
			transform: translate3d(0%, 0, 0);
			overflow: hidden;
			// padding-top: 120px;

			._detail & {
				transform: translate3d(-100vw, 0, 0);
			}
		}

		&_i {
			@include font-size(100px);
			font-weight: fw(thin);
			text-align: center;
			padding: 10px 0;
			line-height: .85;

			.swiper-slide-active & {
				> div {
					opacity: 1;
				}
			}

			> div {
				opacity: .5;
				transition: $transition-base;
				cursor: pointer;

				@include mq($db){
					pointer-events: none;
				}
			}

			i {
				margin-top: 10px;
				display: block;

				&::before,
				&::after {
					@include size(9px);
					content: '';
					border: 2px solid currentColor;
					display: block;
					border-radius: 100%;
					margin: 0 auto;
				}

				> span {
					@include size(1px, 140px);
					display: block;
					background: currentColor;
					margin: 0 auto;
				}
			}
		}

		.sn_swiper_nav {
			transition: transform $transition-slow-easing;
			transform: scale3d(1, 1, 1);

			&._disabled {
				transform: scale3d(0, 0, 0);
			}
		}

		.swiper-container {
			// height: 100%;
			overflow: visible;

			&::after {
				content: '';
				position: absolute;
				top: -100vh;
				bottom: -100vh;
				left: 0;
				right: 0;
			}

			.swiper-wrapper {

				.swiper-slide {
					height: auto;

					&:last-child {
						i {
							// Remove line and dots
							&::before,
							&::after,
							> span {
								opacity: 0;
								pointer-events: none;
							}
						}
					}
				}
			}
		}
	}

	&_text {
		height: 100%;

		@include mq(0px, sm-max){
			@include scut-absolute(0 0 0 0);
			@include scut-padding(n $container-mobile-padding);
			transition: transform $transition-slow-easing;
			transform: translate3d(100vw, 0, 0);

			._detail & {
				transform: translate3d(0%, 0, 0);
			}
		}

		&_title {
			font-size: 24px;

			@include mq(sm){
				font-size: 40px;
			}

			@include mq(lg){
				font-size: 60px;
			}
		}

		&_dismiss {
			@extend %sn_circle_icon_hover_parent;
			@include font-size(16px);
			display: inline-block;

			.sn_circle_icon {
				@include size(60px);
				padding: 18px;
			}
		}

		.swiper-container {
			height: 100%;
			overflow: visible;

			.swiper-wrapper {
				.swiper-slide {
					display: flex;
					align-items: center;
					padding-top: 100px;

					@include mq(md){
						padding-top: 0;
					}
				}
			}
		}
	}
}
