.sn_intro_search {
	form {
		.form-group {

			.form-control {
				@include font-size(16px, 20px, sm);
				height: 40px;
				border-radius: 100px;
				border-color: rgba($primary, 0);
				padding: 0px 15px 0 45px;
				background: rgba($gray-300, .3);
				color: #fff;
				background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='80' height='80' viewBox='0 0 80 80' fill='#{$gray-400}'><path d='M58.8,53.7c4.6-5.8,7.2-12.9,7.2-20.4c0-8.7-3.4-17-9.6-23.2C50.2,3.9,42,0.5,33.3,0.5c-8.7,0-17,3.4-23.2,9.6 c-6.2,6.2-9.6,14.4-9.6,23.2c0,8.7,3.4,17,9.6,23.2c6.2,6.2,14.4,9.6,23.2,9.6c7.5,0,14.7-2.5,20.4-7.2l20.7,20.7l5.1-5.1L58.8,53.7 z M51.3,51.3c-4.8,4.8-11.2,7.5-18,7.5c-6.8,0-13.2-2.7-18-7.5c-4.8-4.8-7.5-11.2-7.5-18c0-6.8,2.6-13.2,7.5-18 c4.8-4.8,11.2-7.5,18-7.5c6.8,0,13.2,2.6,18,7.5c4.8,4.8,7.5,11.2,7.5,18C58.7,40.1,56.1,46.5,51.3,51.3z'/></svg>"));
				background-size: 24px 24px;
				background-position: 15px center;
				background-repeat: no-repeat;

				@include mq(sm){
					height: 60px;
					padding-right: 40px;
					padding-left: 80px;
					background-size: 27px 27px;
					background-position: 30px center;
				}

				&:focus {
					border-color: #fff;
				}

				@include placeholder {
					color: $gray-400;
				};
			}
		}
	}
}
