.swiper-container {
	.swiper-wrapper {
		transition-timing-function: $transition-cubic-bezier;

		.swiper-slide {}
	}
}

.sn_swiper_pagination {
	&.swiper-pagination-fraction {
		.swiper-pagination-current {
			@extend .text-primary;
		}
	}

	//

	&.swiper-pagination-bullets {
		display: inline-flex;
		flex-wrap: wrap;
		justify-content: center;

		&.swiper-pagination-clickable {
			.swiper-pagination-bullet {
				cursor: pointer;
			}
		}

		.swiper-pagination-bullet {
			@include size(14px);
			background: $gray-400;
			border-radius: 100%;
			display: block;
			flex-shrink: 0;
			margin-right: 10px;
			margin-bottom: 10px;
			transition: $transition-base;
			transform: scale3d(.57143, .57143, .57143);

			@include mq($db){
				@include size(20px);
				margin-right: 14px;
				transform: scale3d(.6, .6, .6);
			}

			&:last-child {
				margin-right: 0;
			}

			&.swiper-pagination-bullet-active {
				transform: scale3d(1, 1, 1);
				background: $primary;
				box-shadow: 0 0 16px 0 #355d78;
			}
		}
	}
}

.sn_swiper_nav {
	@extend %sn_circle_icon_hover_parent;
	cursor: pointer;
	pointer-events: all;

	&:focus {
		outline: 0 !important;
	}

	//

	&_wr {
		pointer-events: none;
		z-index: 10;
	}
}
