.sn_anchors {

	&.text-body {
		.sn_circle_icon {
			@extend ._dark;
		}
	}

	a {
		&:hover,
		&:active {
			color: currentColor !important;
			text-decoration: underline;
		}
	}
}
