// SSNT Variables

// Default Breakpoints
$db:				lg; // Use it in @include mq($db){} - Default Breakpoint
$dbm:				md-max; // Use it in @include mq(0px, $dbm){} - Default Breakpoint Max
$db-font:		md; // Used in mixins @font-size, @line-height, @letter-spacing

// Colors
// Bootstrap's Grays
$white:    #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #d0cdc7;
$gray-400: #cfcdc7;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:    #000 !default;

// Bootstrap's Theme Colors
$primary:      	#1570b7;
$secondary:     #38454f;
$success:       #28a745 !default;
$info:          #17a2b8 !default;
$warning:       #ffc107 !default;
$danger:        #dc3545 !default;
$light:         $gray-300;
$dark:          $gray-800;

$theme-colors: () !default;
$theme-colors: map-merge((
	'primary':    $primary,
	'secondary':  $secondary,
	'success':    $success,
	'info':       $info,
	'warning':    $warning,
	'danger':     $danger,
	'light':      $light,
	'dark':       $dark,
	'white':      $white,
	'black':     	$black,
	// 'gray-100': $gray-100,
	// 'gray-200': $gray-200,
	// 'gray-300': $gray-300,
	// 'gray-400': $gray-400,
	// 'gray-500': $gray-500,
	// 'gray-600': $gray-600,
	// 'gray-700': $gray-700,
	// 'gray-800': $gray-800,
	// 'gray-900': $gray-900,
), $theme-colors);

// Body
$body-bg:   		$white !default;
$body-color:		$secondary;


// Z-indexes
// Boostrap
$zindex-dropdown:                   1000 !default;
$zindex-sticky:                     1020 !default;
$zindex-fixed:                      1030 !default;
$zindex-modal-backdrop:             1040 !default;
$zindex-modal:                      1050 !default;
$zindex-popover:                    1060 !default;
$zindex-tooltip:                    1070 !default;
// Custom
$zindex-header:                    	$zindex-fixed + 1;



// Typography
// stylelint-disable value-keyword-case
$heebo:                       'Heebo';
$font-family-sans-serif:      $heebo, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$font-family-serif: 					times, serif;
$font-family-monospace:       SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$font-family-base:            $font-family-sans-serif;


// Structure
$grid-gutter-width:           24px;
$container-mobile-padding:    18px;
$preheader-height-m:          50px;
$preheader-height-d:          40px;
$header-height-m:             75px;
$header-height-d:             100px;
$circle-icon-size:            80px;

// Spacings
$spacers-step: 5;
$spacers-max: 200; // Needs to be divisible by $spacers-step, otherwise will be rounded
$spacers: set-spacers();


// Font Weights
$font-weights: (
  thin				: 100,
	extralight	: 200,
	light				: 300,
	regular			: 400,
	medium			: 500,
	semibold		: 600,
	bold				: 700,
	extrabold		: 800,
	heavy				: 900
);


// Alerts
$alert-padding-y:                   .75rem !default;
$alert-padding-x:                   1.25rem !default;
$alert-margin-bottom:               1rem !default;
$alert-border-radius:               0 !default;
$alert-link-font-weight:            fw(bold) !default;
$alert-border-width:                0 !default;

$alert-bg-level:                    0 !default;
$alert-border-level:                0 !default;
$alert-color-level:                 -12 !default;


// Transitions
$transition-cubic-bezier:				cubic-bezier(.7, 0, 0, 1);
//
$transition-base-time:					.35s !default;
$transition-base-easing:				$transition-base-time ease !default;
$transition-base:								all $transition-base-easing !default;
//
$transition-slow-time:					.75s !default;
$transition-slow-easing:				$transition-slow-time $transition-cubic-bezier !default;
$transition-slow:								all $transition-slow-easing !default;
