.sn_text_background {
	display: flex;
	align-items: center;
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	min-height: calc(100vh - #{$header-height-m});

	@include mq($db){
		min-height: calc(100vh - #{$header-height-d});
	}

	// Fix ‘min-height’ flex IE
	&::after { content: ''; min-height: inherit; font-size: 0; }

	&_ct {
		padding-top: 30px;
		padding-bottom: 30px;
	}
}
