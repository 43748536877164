::selection {
	background: rgba($primary, .99);
  color: #fff;
}
::-webkit-selection {
  background: rgba($primary, .99);
  color: #fff;
}

html {
  -ms-overflow-style: scrollbar;
}

body {
	@include font-size(16px, 20px);

	&::before {
		content: '';
		display: none;
	}

	&._menu_open {
		@include mq(0px, $dbm){
			overflow: hidden;
		}
	}
}

a, button {
  transition: $transition-base;
}

.noscroll {
  overflow: hidden;
}

figure {
	margin: 0;
}

//

a.text-white,
.text-white a {
	color: $white;

	&:hover,
	&:active {
		color: $primary !important;
	}
}

a.text-body,
.text-body a {
	color: $body-color;

	&:hover,
	&:active {
		color: $primary !important;
	}
}

.container,
.container-fluid {
	@include mq(0px, xs-max){
		padding-left: $container-mobile-padding;
		padding-right: $container-mobile-padding;
	}
}

//

.sn_site_wrapper {
	position: relative;
}

// Utils.mq() classes
.sn_utils_mq_xs { display: block;	@include mq(sm){ display: none }; }
.sn_utils_mq_sm { display: none; @include mq(sm){ display: block }; @include mq(md){ display: none }; }
.sn_utils_mq_md { display: none; @include mq(md){ display: block }; @include mq(lg){ display: none }; }
.sn_utils_mq_lg { display: none; @include mq(lg){ display: block }; @include mq(xl){ display: none }; }
.sn_utils_mq_xl { display: none; @include mq(xl){ display: block }; }

// Pre
pre {
	border: 2px solid coral;
	background: #eaeaea;
	border-radius: 4px;
	color: #262626;
	padding: 10px;
	font-size: 16px !important;
}
