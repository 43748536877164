.sn_slider_highlights {
	display: flex;
	align-items: flex-start;
	min-height: calc(100vh - #{$header-height-m});

	@include mq($db){
		min-height: calc(100vh - #{$header-height-d});
		align-items: center;
	}

	// Fix ‘min-height’ flex IE
	&::after { content: ''; min-height: inherit; font-size: 0; }

	//

	.swiper-container {
		width: 100%;

		.swiper-wrapper {
			align-items: center;

			.swiper-slide {
			}
		}
	}

	//

	@include paragraph(20px);
}
