.btn {
	@include font-size(16px);
	padding: (20px - 1px) 25px;
	line-height: 1.25;
	border-radius: 999px;
	text-transform: uppercase;
	font-weight: fw(bold);
	min-width: 200px;
}

.btn-primary {
	background: $primary;

	&[disabled] {
		background-color: $light;
		border-color: $light;
	}
}
