.sn_slider_articles {
	position: relative;
	overflow: hidden;

	._category-title{
		font-size: 60px;
		line-height: 1.13;
		color: #384550;
		font-weight: 100;
		transition: $transition-base;

		&:hover{
			color: currentColor !important;
    	text-decoration: underline;
		}
	}

	.swiper-container {
		overflow: visible;

		.swiper-wrapper {
			.swiper-slide {

				._single-article{
					border-radius: 20px;
 					box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
					padding: 26px 22px;
					position: relative;
					transition: $transition-base;

					&:hover{
						transform: scale3d(1.04, 1.04, 1.04);
					}

					._image{
						position: absolute;
						top: 0; left: 0; right: 0;
						border-top-left-radius: 20px;
						border-top-right-radius: 20px;
						overflow: hidden;
						min-height: 175px;

						figure{
							background-position: center;
							background-repeat: no-repeat;
							background-size: cover;
							min-height: 175px;
							border-top-left-radius: 20px;
							border-top-right-radius: 20px;
						}
					}

					._date{
						width: 100%;
						font-size: 18px;
						font-weight: 100;
						line-height: 1.67;
						color: #384550;
					}

					._title{
						width: 100%;
						font-size: 20px;
						line-height: 1.5;
						font-weight: bold;
						color: #384550;

						display: -webkit-box;
					  -webkit-box-orient: vertical;
					  -webkit-line-clamp: 3;
					  overflow: hidden;
					}

					._link{
					  font-size: 18px;
					  line-height: 1.67;
					  text-align: right;
					  color: #1570b7;

						svg{
							width: 40px;
							height: 30px;
							margin-right: -5px;
							fill: #1570b7;
							transition: $transition-base;

							&:hover{
								fill: #0d4672;
							}
						}
					}
				}
			}
		}
	}
}
