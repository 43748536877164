// Based on http://codepen.io/javasteve99/pen/hDxpn
@mixin font-face($font-family, $file-path, $font-weight: normal, $font-style: normal){
  @font-face{
    font-family: $font-family;
    src: url('#{$file-path}.eot');
    src: url('#{$file-path}.eot?#iefix') format('embedded-opentype'), url('#{$file-path}.woff2') format('woff2'), url('#{$file-path}.woff') format('woff'), url('#{$file-path}.ttf') format('truetype'), url('#{$file-path}.svg##{$font-family}') format('svg');
    font-weight: $font-weight;
    font-style: $font-style;
  }
  // Chrome for Windows rendering fix: http://www.adtrak.co.uk/blog/font-face-chrome-rendering/
  @media screen and (-webkit-min-device-pixel-ratio: 0){
    @font-face{
      font-family: $font-family;
      src: url('#{$file-path}.svg##{$font-family}') format('svg');
    }
  }
}

@mixin is-debug {
	body._debug & {
		@content;
	}
}

@mixin hamburger ($width: 30px, $height: $width, $bar-height: 2px) {
	display: block;
	width: $width;
	height: $height;
	transform: rotate3d(0, 0, 1, 0deg);
	transition: transform $transition-slow-easing;

	> span {
		width: $width;
		height: $bar-height;
		display: block;
		position: absolute;
		left: 0;
		transition:
			background $transition-base-easing,
			transform $transition-slow-easing;

		&:nth-child(1) {
			top: 0;
		}

		&:nth-child(2),
		&:nth-child(3) {
			top: (($height - $bar-height) / 2);
			transform: rotate3d(0, 0, 1, 0deg);
		}

		&:nth-child(4) {
			top: ($height - $bar-height);
		}

		&:nth-child(1),
		&:nth-child(4) {
			transform: rotate3d(0, 1, 0, 0deg);
		}
	}

	//

	// Class now needs to be on `body` element
	._menu_open & {
		transform: rotate3d(0, 0, 1, 180deg);

		> span {
			&:nth-child(1),
			&:nth-child(4) {
				transform: rotate3d(0, 1, 0, 90deg);
			}

			&:nth-child(2) {
				transform: rotate3d(0, 0, 1, -45deg);
			}

			&:nth-child(3) {
				transform: rotate3d(0, 0, 1, 45deg);
			}
		}
	}
}

@mixin headings() {
	h1, h2, h3, h4, h5, h6,
	.h1, .h2, .h3, .h4, .h5, .h6 {
		@content;
	}
}

@mixin paragraph($font-small: null, $font-large: null) {
	&, p {
		@if $font-small {
			@include font-size($font-small, $font-large);
		}

		@content;
	}
}

@mixin placeholder {
  &::-webkit-input-placeholder { @content }
  &:-moz-placeholder           { @content }
  &::-moz-placeholder          { @content }
  &:-ms-input-placeholder      { @content }
}
