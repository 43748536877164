.sn_fab {
	position: fixed;
	right: 30px;
	bottom: 30px;
	z-index: $zindex-fixed;
	fill: $primary;
	color: #fff;

	@include mq($db){
		right: 60px;
		bottom: 60px;
	}

	&:hover,
	&:active {
		fill: darken($primary, $emphasized-link-hover-darken-percentage);
		color: #fff;
	}

	&_title {
		@include font-size(14px, 16px, $db);
		@include scut-absolute(0 0 0 0);
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		padding: 18px;
	}

	.sn_sprite {
		@include size(90px);
		filter: drop-shadow(0px 0px 5px rgba($body-bg, .15)) drop-shadow(0px 0px 5px rgba($secondary, .5));

		@include mq($db){
			@include size(110px);
		}
	}
}

//

.sn_floating_message {
	position: relative;
	z-index: 5;

	&_card {
		@extend .bg-secondary;
		@extend .text-white;
		@extend .mx-auto;
		border-radius: 10px;
		max-width: 540px;
		pointer-events: all;
	}
}

//

.sn_circle_icon {
	@include size($circle-icon-size);
	padding: 25px;
	border: 2px solid #fff;
	fill: #fff;
	color: #fff;
	display: block;
	transition: $transition-base;
	border-radius: 100%;
	flex-shrink: 0;

	&._dark {
		border-color: $secondary;
		fill: $secondary;
		color: $secondary;
	}
}

%sn_circle_icon_hover {
	background: #fff;
	fill: $secondary;
	color: $secondary;
}

%sn_circle_icon_hover_dark {
	background: $secondary;
	fill: #fff;
	color: #fff;
}

%sn_circle_icon_hover_parent {
	cursor: pointer;

	&:hover,
	&:active {
		.sn_circle_icon {
			@extend %sn_circle_icon_hover;

			&._dark {
				@extend %sn_circle_icon_hover_dark;
			}
		}
	}
}

a.sn_circle_icon {
	&:hover,
	&:active {
		@extend %sn_circle_icon_hover;

		&._dark {
			@extend %sn_circle_icon_hover_dark;
		}
	}
}

a {
	@extend %sn_circle_icon_hover_parent;
}

//

.sn_link_bold {
	font-weight: fw(bold);
	text-decoration: underline;

	&:hover,
	&:active {
		text-decoration: underline;
	}
}

//

.sn_info_window {
	@include font-size(18px);

	.name {
		@include font-size(20px);
		font-weight: fw(bold);
		color: $primary;
		border-bottom: 2px solid $primary;
		margin-bottom: 15px;
		padding-bottom: 3px;
	}
}

.gm-style {
	.gm-style-iw-c {
		padding: 0 !important;
	}

	.gm-style-iw-d {
		padding: 20px;
		overflow: visible !important;
		max-height: none !important;

		& + button {
			top: 0 !important;
			right: 0 !important;
		}
	}
}

//

.sn_thank_you {
	background-size: 200% auto;
	background-position: 0% 0%;
	background-repeat: no-repeat;
	min-height: 600px;
	display: flex;
	align-items: center;
	animation: snAbstractBg 100s linear forwards;

	// Fix ‘min-height’ flex IE
	&::after { content: ''; min-height: inherit; font-size: 0; }
}

@keyframes snAbstractBg {
	0%		{ background-position: 0% 0%; }
	100%	{ background-position: 100% 100%; }
}

//

.sn_404 {
	perspective: 1000px;

	&_code {
		@include font-size(100px, 130px, 375px);
		font-weight: fw(bold);

		@include mq(md){
			font-size: 250px;
		}
	}

	&_orbit {
		position: relative;

		figure {
			@include scut-ratio-box;
			width: 100px;
			background-size: cover;
			background-position: center center;
			background-repeat: no-repeat;
			border-radius: 100%;
			animation: snAbstractBg 40s linear alternate forwards infinite;

			@include mq(375px){
				width: 130px;
			}

			@include mq(md){
				width: 270px;
			}
		}

		.shape {
			@include scut-absolute(-30% -30% -30% -30%);
			fill: $light;
			z-index: 1;

			&._1 {
				animation: sn404Orbit1 70s linear infinite reverse;
			}

			&._2 {
				animation: sn404Orbit2 45s linear infinite reverse;
			}
		}
	}
}

@keyframes sn404Orbit1 {
	0%		{ transform: rotate3d(0, 0, 1, 0deg) rotate3d(0, 1, 0, 0deg); }
	100%	{ transform: rotate3d(0, 0, 1, 720deg) rotate3d(0, 1, 0, 360deg); }
}

@keyframes sn404Orbit2 {
	0%		{ transform: rotate3d(0, 0, 1, 0deg) rotate3d(0, 1, 0, 0deg); }
	100%	{ transform: rotate3d(0, 0, 1, -720deg) rotate3d(0, 1, 0, -360deg); }
}

//

.sn_orbit {
	position: relative;
	display: inline-block;
	margin-right: -70px;

	@include mq(sm){
		margin-right: -100px;
	}

	@include mq(md){
		margin-right: -130px;
	}

	figure {
		@include scut-ratio-box;
		width: 270px;
		background-size: 200% auto;
		background-position: 0% 0%;
		background-repeat: no-repeat;
		border-radius: 100%;
		// transition: transform .5s cubic-bezier(0, 0, 0.3, 1);
		// animation: snAbstractBg 70s linear alternate forwards infinite;

		@include mq(sm){
			width: 370px;
		}

		@include mq(md){
			width: 470px;
		}
	}

	.shape {
		@include scut-absolute(0 0 0 0);
		fill: $light;
		transition: transform .5s cubic-bezier(0, 0, 0.3, 1);
	}

	//

	&_wr {
		position: relative;
	}

	&_blocks {
		position: relative;
		z-index: 1;
	}

	&_ct {
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
	}

	&_in {
		position: sticky;
		top: 0;
		right: 0;
		display: flex;
		align-items: flex-end;
		justify-content: flex-end;
		height: 100vh;
		width: 100vw;
		overflow: hidden;
		padding-bottom: $header-height-m;

		@include mq($db){
			padding-top: $header-height-d;
			align-items: center;
		}
	}

	&_shapes {
		@include scut-absolute(-30% -30% -30% -30%);
		z-index: 1;
		transition: transform .5s cubic-bezier(0, 0, 0.3, 1);
	}
}


/* Bootstrap */
.alert {
	@include font-size(14px, 18px);
	padding: 20px 30px;
	text-align: center;
	font-weight: fw(medium);
	border-radius: 5px;
	margin-bottom: 25px;
}
