.sn_forms_contacts {
	&_nav {
		.swiper-container {
			.swiper-wrapper {
				.swiper-slide {
					width: auto;
					padding: 0 20px 20px;
					min-width: 200px;
					text-align: center;
					cursor: pointer;
					transition: $transition-base;

					&::after {
						content: '';
						@include scut-absolute(n 0 0 0);
						height: 2px;
						background: $primary;
						opacity: 0;
						transition: $transition-base;
					}

					&._active {
						color: $primary;
						font-weight: fw(bold);

						&::after {
							opacity: 1;
						}
					}
				}
			}
		}
	}
}
