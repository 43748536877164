.sn_preheader {
	@extend .bg-secondary;
	@extend .text-white;
	@include font-size(14px);
	height: $preheader-height-m;
	display: flex;
	align-items: center;

	@include mq($db){
		height: $preheader-height-d;
	}

	&_langs {
		display: flex;

		a {
			border-right: 1px solid #fff;
			padding: 0 6px;
			line-height: 1.2;

			&:last-child {
				border: 0;
			}

			&._active {
				font-weight: fw(bold);
				text-decoration: underline;
				pointer-events: none;
			}
		}
	}

	form {
		.form-group {
			min-width: 250px;

			.form-control {
				@include font-size(14px);
				height: 28px;
				border-radius: 100px;
				border-color: rgba($primary, 0);
				padding: 0px 15px;

				&:focus {
					border-color: $primary;
				}
			}
		}
	}

	a {
		.sn_sprite {
			@include size(26px);
			fill: currentColor;
		}
	}
}
