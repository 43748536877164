.sn_prefooter {
	form {
		.form-group {
			margin-bottom: 8px;
			text-align: center;

			@include mq($db){
				display: flex;
				align-items: stretch;
			}

			.form-control {
				@include font-size(18px);
				height: 60px;
				border-radius: 100px;
				border-color: rgba($primary, 0);
				padding: 0 30px;
				margin-right: -30px; // Border-radius

				@include mq($db){
					border-radius: 100px 0 0 100px;
					padding: 0 45px 0 30px;
				}

				&:focus {
					border-color: $primary;
				}
			}
		}

		.custom-control {
			&-label {
				&::before {
					border: 0;
				}
			}
		}
	}
}
