// From Bootstrap
@include headings {
  margin-bottom: $headings-margin-bottom;
  font-family: $headings-font-family;
  font-weight: $headings-font-weight;
  line-height: $headings-line-height;
  color: $headings-color;
}

h1,
.h1 {
	@include font-size(40px, 60px);
}

h2,
.h2 {
	@include font-size(30px, 40px);
}

h3,
.h3 {
	@include font-size(26px, 30px);
}

h4,
.h4 {
	@include font-size(18px, 22px);
}

h5,
.h5 {
	@include font-size(16px, 20px);
}

h6,
.h6 {

}

p,
.p {
	@include font-size(16px, 20px);

	&:last-child {
		margin: 0;
	}
}

small,
.small {
	@include font-size(14px, 18px);
}

strong {
	font-weight: fw(bold);
}

hr {
	border-color: $secondary;
}

ul, ol {
	padding-inline-start: 25px;

	&:last-child {
		margin-bottom: 0;
	}
}

//

.display-1,
.display-2 {
	line-height: $display-line-height;
}

.display-1 {
	@include font-size(120px, 180px);
}

.display-2 {
	@include font-size(55px, 100px);
}

//

.sn_fw_thin { font-weight: fw(thin); }
.sn_fw_light { font-weight: fw(light); }
.sn_fw_bold { font-weight: fw(bold); }

//

.sn_text {
	&._small {
		&, p {
			@include font-size(14px, 16px);
		}
	}

	ol {
		li {
			&::marker {
				font-weight: fw(bold);
			}
		}
	}

	a {
		color: currentColor;
		text-decoration: underline;

		&:hover,
		&:active {
			color: $primary;
		}
	}

	hr {
		margin: 40px auto;
	}
}
