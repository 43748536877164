.sn_magazine_detail {

	.taxonomy_link{
		font-size: 18px;
	  font-weight: normal;
	  font-stretch: normal;
	  font-style: normal;
	  line-height: 1.67;
	  letter-spacing: normal;
	  color: #1570b7;
	}

	.excerpt{
		font-size: 30px;
	  font-weight: 100;
	  font-stretch: normal;
	  font-style: normal;
	  line-height: normal;
	  letter-spacing: normal;
	  color: #384550;
	}

	.date{
		font-size: 18px;
	  font-weight: 100;
	  font-stretch: normal;
	  font-style: normal;
	  line-height: 1.67;
	  letter-spacing: normal;
	  color: #384550;
	}

	.share{
		padding: 17px 0px;
		border-top: 1px solid #808080;
		border-bottom: 1px solid #808080;
		display: flex;
		align-items: center;

		.social{
			margin-right: 20px;
			font-size: 18px;
			font-weight: bold;
		  font-stretch: normal;
		  font-style: normal;
		  line-height: 1.67;
		}

		.sn_sprite{
			fill: #384550;
			width: 36px;
			height: 36px;
			display: block;
			margin-right: 24px;
		}

		button{
			border: 0px;
			padding: 0px;
			background: none;

			&:focus,
			&:hover{
				outline: none;
			}
		}
	}

	.content{
		p{
			@include font-size(16px, 22px);
		  font-weight: 300;
		  font-stretch: normal;
		  font-style: normal;
		  line-height: 1.5;
		  letter-spacing: normal;
		  color: #384550;
		}
	}
}
