.sn_text_links {
	&_l {
		a {
			font-size: 60px;

			@include mq(lg){
				font-size: 40px;
			}

			@include mq(xl){
				font-size: 60px;
			}

			&:hover,
			&:active {
				color: currentColor !important;

				@include mq($db){
					text-decoration: underline;
				}
			}

			&._small {
				@include font-size(16px, 30px);
				text-decoration: underline;

				@include mq($db){
					text-decoration: none;
				}
			}
		}
	}
}
