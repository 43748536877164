.sn_cards_links {

	.title{
		@include font-size(30px);
		font-weight: fw(bold);
		color: $white;
		width: 100%;
	}

	.content{
		@include font-size(22px);
		font-weight: fw(light);
		color: $white;
		width: 100%;
	}

	&_i {
		@include font-size(40px);
		font-weight: fw(thin);
		position: relative;
		display: flex;
		align-items: center;
		height: 100%;
		color: $body-color;

		&:hover,
		&:focus,
		&:active {
			&::before {
				transform: scale3d(1.04, 1.04, 1.04);
			}
		}

		&::before {
			@include scut-absolute(0 0 0 0);
			content: '';
			background: #fff;
			border-radius: 20px;
			transform: scale3d(1, 1, 1);
			transition: $transition-base;
		}

		&_in {
			@include scut-padding(35px 35px 55px);
			position: relative;
			width: 100%;
			z-index: 1;
			height: 100%;
			display: flex;
	 		justify-content: space-between;
	 		align-items: center;
	 		flex-direction: column;

			.title{
				margin:0;
			}

			span{
				margin:0;
			}
		}
	}
}
