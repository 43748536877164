// Redefined deprecated Bootstrap's 'size'
@mixin size($width, $height: $width) {
  width: $width;
  height: $height;
}

// Fonts
@mixin font-size($font-small, $font-large: null, $breakpoint: $db-font) {
	font-size: $font-small;

	@if $font-large != null {
		@include mq($breakpoint) {
			font-size: $font-large;
		}
	}
}

@mixin line-height($lh-small, $lh-large: null, $breakpoint: $db-font) {
	line-height: $lh-small;

	@if $lh-large != null {
		@include mq($breakpoint) {
			line-height: $lh-large;
		}
	}
}

@mixin letter-spacing($ls-small, $ls-large: null, $breakpoint: $db-font) {
	letter-spacing: $ls-small;

	@if $ls-large != null {
		@include mq($breakpoint) {
			letter-spacing: $ls-large;
		}
	}
}
