.sn_header {
	@include scut-absolute(0 0 n 0);
	@include font-size(20px, 18px);
	height: $header-height-m;
	display: flex;
	align-items: center;
	z-index: $zindex-header;
	transition: $transition-base;
	box-shadow: 0 0 0px rgba(#000, .5);

	@include mq($db){
		height: $header-height-d;
	}

	@include mq(lg){
		font-size: 14px!important
	}

	@include mq(xl){
		font-size: 18px!important
	}

	&._sticky {
		position: fixed;
		background: $body-bg;
		box-shadow: 0 0 6px rgba(#000, .5);
	}

	&._fixed {
		position: fixed;
	}

	._menu_open & {
		box-shadow: 0 0 0px rgba(#000, .5);
	}

	&._light {
		&:not(._sticky) {
			color: #fff;

			a {
				color: #fff;

				&:hover,
				&:active {
					color: $primary;
				}
			}
		}
	}

	&_logo {
		@include size(137px, 36px);
		fill: currentColor;
		display: block;
		transition: color $transition-base-easing;

		@include mq(lg){
			@include size(194px, 54px);
		}

		@include mq(xl){
			@include size(224px, 72px);
		}
	}

	&_menu {
		@include mq(0px, $dbm){
			position: fixed;
			top: $header-height-m;
			bottom: 0;
			left: 100%;
			width: 100vw;
			background: $secondary;
			z-index: $zindex-fixed;
			overflow-y: auto;
			transform: translate3d(0%, 0, 0);
			transition: transform $transition-slow-easing;

			@include mq($db){
				top: $header-height-d;
			}

			._menu_open & {
				transform: translate3d(-100%, 0, 0);
			}
		}

		&_toggle {
			@include hamburger($width: 23px, $height: 22px, $bar-height: 2px);

			&:hover,
			&:active {
				> span {
					background: $primary;
				}
			}

			._light:not(._sticky) & {
				> span {
					background: #fff;
				}

				&:hover,
				&:active {
					> span {
						background: $primary;
					}
				}
			}

			> span {
				background: $body-color;
			}
		}

		&_child {
			margin-top: 15px;

			@include mq($db){
				position: absolute;
				top: 100%; left: 0;
				width: 200px;
				padding-top: 15px;
				margin: 0;
				text-align: left;
				display: none;
			}

			@include mq(0px, $dbm){
				// Override "style" from JS
				// display: block !important;
				// position: static !important;
				// transform: none !important;
				// opacity: 1 !important;
			}

			> ul {
				padding: 0;

				@include mq($db){
					background: #fff;
					padding: 15px;
					border-radius: 5px;
					box-shadow: 0 2px 4px 0 rgba(#000, .5);
				}

				> li {
					margin-bottom: 10px;

					@include mq($db){
						padding-bottom: 10px;
						border-bottom: 1px solid $secondary;
					}

					&:last-child {
						border-bottom: 0;
						margin-bottom: 0;
						padding-bottom: 0;
					}

					&.current-menu-item {
						> a {
							font-weight: fw(bold);
						}
					}

					> a {
						@include font-size(16px, 14px);

						@include mq($db){
							color: $secondary !important;
						}

						&:hover,
						&:active {
							color: $primary !important;
						}
					}
				}
			}
		}

		ul {
			margin: 0;
			list-style: none;

			li {
				a {
					@include mq(0px, $dbm){
						color: #fff;
					}
				}
			}
		}

		> ul {
			display: block;
			padding: 0 20px 20px;
			border-top: 1px solid #fff;

			@include mq($db){
				display: flex;
				justify-content: flex-end;
				align-items: center;
				text-align: center;
				padding: 0;
				border-top: 0;
			}

			> li {
				position: relative;
				display: block;
				padding: 15px;
				border-bottom: 1px solid #fff;

				@include mq($db){
					padding: 0;
					border-bottom: 0;
				}

				@include mq(lg){
					margin-right: 20px;
				}

				@include mq(xl){
					margin-right: 40px;
				}

				&:last-child {
					margin-right: 0;
				}

				&._open {
					> a,
					> span {
						.sn_sprite {
							transform: rotate3d(1, 0, 0, 180deg);
						}
					}
				}

				&.current-menu-item,
				// &.current-menu-parent,
				&.current-menu-ancestor {
					> a,
					> span {
						font-weight: fw(bold);
						position: relative;

						&::before {
							content: '';
							@include scut-absolute(-4px -13px n n);
							@include size(38px);
							background-size: contain;
							background-image: escape-svg(url("data:image/svg+xml,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' width='39px' height='37px' viewBox='0 0 39 37'> <path fill='#{$primary}' d='M0.8,30.2c6.3,5.8,15.4,8,23.4,4.9 c8.2-3.1,13.3-10.9,14.2-19.7c0.5-4.9-0.6-9.8-3.1-14c-1.2-2-4.1-0.2-3,1.8c3.3,6.4,2.9,14.3-0.5,20.6c-3.7,6.7-10.7,10-18,9.7 c-4.4-0.2-8.5-1.5-11.8-4.6C1.2,28.1-0.1,29.4,0.8,30.2'/> </svg>"));
							background-repeat: no-repeat;
						}
					}
				}

				> a,
				> span {
					text-transform: uppercase;
					display: inline-flex;
					align-items: center;
					fill: currentColor;

					.sn_sprite {
						@include size(10px);
						transition: transform $transition-base-easing;
						transform: rotate3d(1, 0, 0, 0deg);
					}
				}

				> span {
					cursor: default;
				}

			}
		}
	}

	&_search {
		position: fixed;
		left: 0;
		right: 0;
		bottom: 100%;
		height: 100%;
		z-index: $zindex-fixed;
		background: $secondary;
		transform: translate3d(0, 0%, 0);
		transition: $transition-slow-easing;
		color: $body-color;

		._search_open & {
			transform: translate3d(0, 100%, 0);
		}

		&_close {
			position: absolute;
			top: 10px;
			right: 10px;
			z-index: 1;

			@include mq($db){
				top: 50px;
				right: 50px;
			}

			.sn_circle_icon {
				background: $secondary;
			}
		}

		&_wr {
			display: flex;
			align-items: center;
			height: 100%;
			overflow-y: auto;
		}

		&_in {
			width: 100%;
			margin: auto;
			padding: ($circle-icon-size + (10px * 2)) 0;

			@include mq($db){
				padding: ($circle-icon-size + (50px * 2)) 0;
			}
		}

		&_frequent {
			border-radius: 10px;

			a {
				@extend .text-body;
			}
		}

		form {
			.form-group {

				.form-control {
					@include font-size(16px, 20px, sm);
					height: 40px;
					border-radius: 100px;
					border-color: rgba($primary, 0);
					padding: 0px 15px 0 45px;
					background: rgba($gray-300, .3);
					color: #fff;
					background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='80' height='80' viewBox='0 0 80 80' fill='#{$gray-400}'><path d='M58.8,53.7c4.6-5.8,7.2-12.9,7.2-20.4c0-8.7-3.4-17-9.6-23.2C50.2,3.9,42,0.5,33.3,0.5c-8.7,0-17,3.4-23.2,9.6 c-6.2,6.2-9.6,14.4-9.6,23.2c0,8.7,3.4,17,9.6,23.2c6.2,6.2,14.4,9.6,23.2,9.6c7.5,0,14.7-2.5,20.4-7.2l20.7,20.7l5.1-5.1L58.8,53.7 z M51.3,51.3c-4.8,4.8-11.2,7.5-18,7.5c-6.8,0-13.2-2.7-18-7.5c-4.8-4.8-7.5-11.2-7.5-18c0-6.8,2.6-13.2,7.5-18 c4.8-4.8,11.2-7.5,18-7.5c6.8,0,13.2,2.6,18,7.5c4.8,4.8,7.5,11.2,7.5,18C58.7,40.1,56.1,46.5,51.3,51.3z'/></svg>"));
					background-size: 24px 24px;
					background-position: 15px center;
					background-repeat: no-repeat;

					@include mq(sm){
						height: 60px;
						padding-right: 40px;
						padding-left: 80px;
						background-size: 27px 27px;
						background-position: 30px center;
					}

					&:focus {
						border-color: #fff;
					}

					@include placeholder {
						color: $gray-400;
					};
				}
			}
		}

		//

		&_btn {
			@include size(22px);
			fill: currentColor;
			display: block;
		}
	}

	a {
		color: $body-color;
		transition:
			color $transition-base-easing,
			fill $transition-base-easing;

		&:hover,
		&:active {
			color: $primary;
		}
	}

	//

	&_spacer {
		height: $header-height-m;

		@include mq($db){
			height: $header-height-d;
		}
	}
}
