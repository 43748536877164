.sn_slider_full {
	@extend .text-white;

	&_i {
		background-size: cover;
		background-position: center center;
		background-repeat: no-repeat;
		display: flex;
		align-items: center;
		min-height: calc(100vh - #{$header-height-m});

		@include mq($db){
			min-height: calc(100vh - #{$header-height-d});
		}

		// Fix ‘min-height’ flex IE
		&::after { content: ''; min-height: inherit; font-size: 0; }

		&_in {
			padding-top: 30px;
			padding-bottom: 30px;
		}
	}

	//

	.sn_swiper_nav_wr {

		@include mq($db){
			@include scut-absolute(50% 0 n 0);
			transform: translateY(-50%);
		}
	}

	//

	.swiper-container {
		.swiper-wrapper {
			align-items: stretch;

			.swiper-slide {
				height: auto;
			}
		}
	}
}
