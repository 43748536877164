.sn_intro_media {
	$breadcrumb-height:
		20px + // ~ height of the text
		20px + // ~ height of the margin
		20px; // a little space

	position: relative;
	display: flex;
	align-items: center;
	overflow: hidden;
	min-height: 100vh;

	@include mq($db){
		min-height: 100vh;
	}

	._show_preheader & {
		min-height: calc(100vh - #{$preheader-height-m});

		@include mq($db){
			min-height: calc(100vh - #{$preheader-height-d});
		}
	}

	// Fix ‘min-height’ flex IE
	&::after { content: ''; min-height: inherit; font-size: 0; }

	&_ct {
		position: relative;
		z-index: 1;
		padding-top: $header-height-m + $breadcrumb-height;
		padding-bottom: $header-height-m + $breadcrumb-height;

		@include mq($db){
			padding-top: $header-height-d + $breadcrumb-height;
			padding-bottom: $header-height-d + $breadcrumb-height;
		}
	}

	&_bg {
		@include scut-absolute(0 0 0 0);
		background-size: cover;
		background-position: center center;
		background-repeat: no-repeat;
	}

	&_video {
		@include scut-absolute(0 0 0 0);

		.plyr {
			@include scut-absolute(0 0 0 0);

			// For JS - `opacity` set in `style`
			transition: 1s $transition-slow-easing;

			.plyr__video-wrapper {
				@include scut-absolute(0 0 0 0);
				height: auto;
				padding: 0 !important;

				.plyr__video-embed__container,
				.plyr__poster {
					@include size(100vw, 100vh);
					position: absolute !important;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%) !important;
					border: 0;

					@media (min-aspect-ratio: 16/9) {
						/* height = 100 * (9 / 16) = 56.25 */
						height: 56.25vw;
					}

					@media (max-aspect-ratio: 16/9) {
						/* width = 100 / (9 / 16) = 177.777777 */
						width: 177.78vh;
					}
				}

				.plyr__video-embed__container {
					padding: 0 !important;
				}

				.plyr__poster {
					background-size: cover;
					background-position: center center;
					background-repeat: no-repeat;
				}
			}
		}
	}
}
